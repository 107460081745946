<template>
  <div>
    <div style="padding: 5px 0">
      <el-input
        v-model="search.meetingName"
        @keyup.enter.native="load"
        style="width: 200px; margin-right: 10px"
        placeholder="请输入会议名称"
      >
        <i slot="prefix" class="el-input__icon el-icon-search"></i>
      </el-input>
      <el-select v-model.number="search.meetingStatus" clearable>
        <el-option
          v-for="item in statusInfo"
          :key="item.id"
          :label="item.statusName"
          :value="item.id"
        ></el-option>
      </el-select>
      <el-button
        @click="load"
        type="primary"
        size="mini"
        style="margin: 10px"
        icon="el-icon-search"
        >搜索</el-button
      >
      <el-button
        @click="add"
        type="primary"
        size="mini"
        style="margin: 10px"
        icon="el-icon-plus"
        >新增</el-button
      >
      <el-button
        icon="el-icon-minus"
        type="danger"
        size="mini"
        style="margin-right: 10px"
        @click="batchDelete"
        :disabled="this.tableChecked.length === 0"
      >
        批量删除
      </el-button>
      <el-button 
      icon="el-icon-download"
      type="primary"
      size="mini"
      style="margin-right: 10px"
      @click="batchExport">
        批量导出
      </el-button>
      <el-button
        size="medium"
        class="el-icon-refresh"
        style="float: right; border: none"
        @click="load"
        >刷新数据</el-button
      >
    </div>
    <el-table
      :data="tableData"
      border
      stripe
      v-loading="loading"
      style="width: 100%"
      @selection-change="handleBatchDelete"
    >
    <el-table-column
        width="50"
        align="center"
        type="selection"
      ></el-table-column>
      <el-table-column
        label="序号"
        width="50"
        type="index"
        :index="indexMethod"
      ></el-table-column>
      <!-- <el-table-column prop="id" label="ID" width="100" sortable> </el-table-column> -->
      <el-table-column prop="meetingName" label="会议名称" width="70"></el-table-column>
      <el-table-column prop="userId" label="负责人工号" width="85">
      </el-table-column>
      <el-table-column prop="headerName" label="负责人" width="70">
      </el-table-column>
      <el-table-column prop="meetingPlace" label="会议地点" width="83"></el-table-column>
      <el-table-column prop="meetingLevel" label="会议等级" width="70">
        <template slot-scope="scope">
          <p v-if="scope.row.meetingLevel === 1">校级</p>
          <p v-if="scope.row.meetingLevel === 2">系级</p>
          <p v-if="scope.row.meetingLevel === 3">班级</p>
        </template>
      </el-table-column>
      <el-table-column prop="meetingStatus" label="会议状态" width="100">
        <template slot-scope="scope">
          <el-tag type="danger" v-if="scope.row.meetingStatus === 1"
            >未审核</el-tag
          >
          <el-tag type="warning" v-if="scope.row.meetingStatus === 2"
            >审核中</el-tag
          >
          <el-tag type="success" v-if="scope.row.meetingStatus === 3"
            >审核通过</el-tag
          >
          <el-tag v-if="scope.row.meetingStatus === 4">无需审核</el-tag>
          <el-tag type="info" v-if="scope.row.meetingStatus === 5"
            >审核未通过</el-tag
          >
        </template>
      </el-table-column>

      <el-table-column
        prop="totalNumber"
        label="总人数"
        width="60"
      ></el-table-column>

      <el-table-column prop="isSignin" label="是否签到" width="50">
        <template slot-scope="scope">
          <p v-if="scope.row.isSignin === '0'">否</p>
          <p v-if="scope.row.isSignin === '1'">是</p>
        </template>
      </el-table-column>
      <el-table-column prop="isSignout" label="是否签退" width="50">
        <template slot-scope="scope">
          <p v-if="scope.row.isSignout === '0'">否</p>
          <p v-if="scope.row.isSignout === '1'">是</p>
        </template>
      </el-table-column>

      <el-table-column prop="startTime" label="开始日期" width="90"></el-table-column>
      <el-table-column prop="endTime" label="结束日期" width="90"></el-table-column>
      <!-- <el-table-column prop="meetingContent" label="会议描述"></el-table-column> -->
      <el-table-column label="图片"
        ><template slot-scope="scope"
          ><el-image
            style="width: 80px; height: 80px"
            :src="scope.row.img"
            :preview-src-list="[scope.row.img]"
          ></el-image></template
      ></el-table-column>

      <el-table-column fixed="right" label="操作" width="185">
        <template slot-scope="scope">
          <el-button
            v-if="scope.row.meetingStatus === 1"
            type="primary"
            icon="el-icon-edit"
            circle
            @click="edit(scope.row)"
          ></el-button>
          <!-- <el-button v-else-if="scope.row.meetingStatus === 2" type="primary" icon="el-icon-edit" circle  @click="edit(scope.row)"></el-button> -->
          <el-button
            v-else-if="scope.row.meetingStatus === 4"
            type="primary"
            icon="el-icon-edit"
            circle
            @click="edit(scope.row)"
          ></el-button>
          <el-button
            v-else-if="scope.row.meetingStatus === 5"
            type="primary"
            icon="el-icon-edit"
            circle
            @click="edit(scope.row)"
          ></el-button>

          <el-button
            v-else
            disabled
            type="primary"
            icon="el-icon-edit"
            circle
            @click="edit(scope.row)"
          ></el-button>
          <el-popconfirm @confirm="del(scope.row.id)" title="确定删除？">
            <el-button
              type="danger"
              icon="el-icon-delete"
              circle
              slot="reference"
              style="margin-left: 10px"
            ></el-button>
          </el-popconfirm>

          <el-button
            v-if="
              scope.row.meetingStatus === 1 || scope.row.meetingStatus === 5
            "
            type="warning"
            round
            style="margin-left: 10px"
            @click="sumbitCheck(scope.row)"
            >提交审核</el-button
          >
          <!-- <el-button v-else-if="scope.row.meetingStatus === 5" type="warning" round style="margin-left:10px" @click="sumbitCheck(scope.row)">提交审核</el-button> -->
          <el-button
            v-else
            type="warning"
            disabled
            round
            style="margin-left: 10px"
            @click="sumbitCheck(scope.row)"
            >提交审核</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div style="margin-top: 10px">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNum"
        :page-size="pageSize"
        :page-sizes="[2, 5, 10]"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>

    <!-- 弹窗 -->
    <el-dialog
      title="会议信息"
      :visible.sync="dialogFormVisible"
      width="50%"
      :close-on-click-modal="false"
    >
      <el-form :model="entity">
        <el-form-item label="会议名称" label-width="120px">
          <el-input
            v-model="entity.meetingName"
            autocomplete="off"
            style="width: 80%"
          ></el-input>
        </el-form-item>

          <el-form-item label="负责人工号" label-width="120px">
          <!-- <el-input v-model="entity.headerId" autocomplete="off" style="width: 80%"></el-input> -->
          <el-select
            v-model="entity.userId"
            filterable
          >
            <el-option
              v-for="item in userInfo"
              :key="item.id"
              :label="item.username"
              :value="item.username"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="负责人" label-width="120px">
          <!-- <el-input v-model="entity.headerId" autocomplete="off" style="width: 80%"></el-input> -->
          <el-select
            v-model="entity.headerName"
            filterable
          >
            <el-option
              v-for="item in userInfo"
              :key="item.id"
              :label="item.nickName"
              :value="item.nickName"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="会议地点" label-width="120px">
          <el-input
            v-model="entity.meetingPlace"
            autocomplete="off"
            style="width: 80%"
          ></el-input>
        </el-form-item>

        <el-form-item label="总人数" label-width="120px">
          <el-input-number
            v-model="entity.totalNumber"
            autocomplete="off"
            style="width: 39%"
          ></el-input-number>
        </el-form-item>

        <el-form-item label="会议等级" label-width="120px">
          <!-- <el-input v-model="entity.meetingLevel" autocomplete="off" style="width: 80%"></el-input> -->
          <el-select v-model="entity.meetingLevel" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.id"
              :label="item.levelName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <template v-if="entity.meetingStatus != null">
          <el-form-item label="会议状态" label-width="120px">
            <!-- <el-input v-model="entity.meetingStatus" autocomplete="off" style="width: 80%"></el-input> -->
            <el-tag
              v-if="entity.meetingStatus === 1"
              v-model="entity.meetingStatus"
              type="danger"
              >{{ status }}</el-tag
            >
            <el-tag
              v-if="entity.meetingStatus === 2"
              v-model="entity.meetingStatus"
              type="warning"
              >{{ status }}</el-tag
            >
            <el-tag
              v-if="entity.meetingStatus === 4"
              v-model="entity.meetingStatus"
              type="primary"
              >{{ status }}</el-tag
            >
            <el-tag
              v-if="entity.meetingStatus === 5"
              v-model="entity.meetingStatus"
              type="info"
              >{{ status }}</el-tag
            >
          </el-form-item>
        </template>

        <el-form-item label="是否签到" label-width="120px">
          <el-radio v-model="entity.isSignin" label="1" border size="medium">是</el-radio>
          <el-radio v-model="entity.isSignin" label="0" border size="medium">否</el-radio>
        </el-form-item>

        <el-form-item label="是否签退" label-width="120px">
          <el-radio v-model="entity.isSignout" label="1" border size="medium">是</el-radio>
          <el-radio v-model="entity.isSignout" label="0" border size="medium">否</el-radio>
        </el-form-item>

        <el-form-item label="开始日期" label-width="120px">
          <el-date-picker
            style="width: 80%"
            v-model="entity.startTime"
            type="datetime"
            value-format="yyyy-MM-dd HH:mm:ss"
            placeholder="选择日期时间"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="结束日期" label-width="120px">
          <el-date-picker
            style="width: 80%"
            v-model="entity.endTime"
            type="datetime"
            value-format="yyyy-MM-dd HH:mm:ss"
            placeholder="选择日期时间"
          ></el-date-picker>
        </el-form-item>
        
        <el-form-item label="会议描述" label-width="120px">
          <el-input
            type="textarea"
            @input="descInput"
            maxlength="50"
            v-model="entity.meetingContent"
            autocomplete="off"
            style="width: 80%"
          >
          </el-input>
          <span>{{ remnant }}/50</span>
        </el-form-item>
        <el-form-item label="图片" label-width="120px">
          <el-upload
            action="http://localhost:9999/files/upload"
            :on-success="fileSuccessUpload"
            :file-list="fileList"
          >
            <el-button size="small" type="primary">点击上传</el-button>
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="save">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import API from "../../utils/request";
const url = "/api/meeting/";
const exportUrl = "http://localhost:9999/api/meeting/"

export default {
  name: "Meeting",
  data() {
    return {
      loading: false,
      fileList: [],
      options: [],
      tableChecked:[],
      text: "",
      user: {},
      tableData: [],
      pageNum: 1,
      pageSize: 5,
      entity: {},
      total: 0,
      dialogFormVisible: false,
      userInfo: [],
      remnant: 50,
      search: {
        meetingName: "",
        meetingStatus: "",
      },
      statusInfo: [],
    };
  },
  computed: {
    // 设置当会议状态码为不同数字时展示的内容
    status() {
      if (this.entity.meetingStatus === 1) {
        return "未审核";
        // return this.statusInfo.statusName
      }
      if (this.entity.meetingStatus === 2) {
        return "审核中";
      }
      if (this.entity.meetingStatus === 3) {
        return "审核通过";
      }
      if (this.entity.meetingStatus === 4) {
        return "无需审核";
      }
      if (this.entity.meetingStatus === 5) {
        return "审核未通过";
      }
      if (this.entity.meetingStatus == null) {
        return "未审核";
      }
    },
  },
  created() {
    this.user = sessionStorage.getItem("user")
      ? JSON.parse(sessionStorage.getItem("user"))
      : {};
    this.load();
  },
  methods: {
    descInput() {
      const txtVal = this.entity.meetingContent.length;
      this.remnant = 50 - txtVal;
    },
     handleBatchDelete(val) {
      // console.log("val",val)
      this.tableChecked = [];
      val.forEach((element) => {
        this.tableChecked.push(element.id);
        // console.log("tableChecked",this.tableChecked)
      });
    },
    batchDelete() {
        this.$confirm('此操作将永久删除会议信息，是否继续？','提示',{
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
      var idsParams = {
        'ids': this.tableChecked,
      };
      API.post(url + "batchDelete", idsParams).then((res) => {
        if (res.code === "0") {
          this.load()
          this.$message({
          type: 'success',
          message: '删除成功!'
        });
        
        }
        if (res.code === "-1") {
          this.$message({
          type: 'error',
          message: '删除失败'
        });
        }
      });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    batchExport() {
      window.location.href=exportUrl + "exportMeetingInfo";
    },
    indexMethod(index) {
      return index + 1;
    },
    fileSuccessUpload(res) {
      this.entity.file = "http://localhost:9999/files/" + res.data;
      this.entity.img = "http://localhost:9999/files/" + res.data;
      this.fileList = [res.data];
      console.log("file.res", res);
    },
    handleSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.load();
    },
    handleCurrentChange(pageNum) {
      this.pageNum = pageNum;
      this.load();
    },

    load() {
      API.post(
        url + "page/" + this.pageNum + "/" + this.pageSize + "/" + this.user.username,
        this.search
      ).then((res) => {
        this.tableData = res.data.records || [];
        this.total = res.data.total;
      });

      API.get("/api/level").then((res) => {
        this.options = res.data;
      });

      API.get("/api/user/findUserById/" + this.user.id).then((res) => {
        // console.log("res.data", res);
        if (res.code === "0") {
          this.userInfo.push(res.data);
        }
      });

      API.get("/api/status/findAll").then((res) => {
        this.statusInfo = res.data;
        console.log("statusInfo",res.data)
      });
    },
    add() {
      this.entity = {};
      this.fileList = [];
      this.dialogFormVisible = true;
    },
    edit(obj) {
      this.entity = JSON.parse(JSON.stringify(obj));
      this.fileList = [];
      this.dialogFormVisible = true;
    },
    sumbitCheck(checkInfo) {
      this.entity = JSON.parse(JSON.stringify(checkInfo));
      API.put(url + "/updateCheck", this.entity).then((res) => {
        if (res.code === "0") {
          this.$message({
            type: "success",
            message: "操作成功",
          });
        } else {
          this.$message({
            type: "error",
            message: res.msg,
          });
        }
        this.load();
      });
    },
    save() {
      if (!this.entity.id) {
        if (this.entity.meetingStatus == null) {
          this.entity.meetingStatus = 1;
        }
        API.post(url + "/save", this.entity).then((res) => {
          if (res.code === "0") {
            this.$message({
              type: "success",
              message: "操作成功",
            });
          } else {
            this.$message({
              type: "error",
              message: res.msg,
            });
          }
          this.load();
          this.dialogFormVisible = false;
        });
      } else {
        API.put(url + "/update", this.entity).then((res) => {
          if (res.code === "0") {
            this.$message({
              type: "success",
              message: "操作成功",
            });
          } else {
            this.$message({
              type: "error",
              message: res.msg,
            });
          }
          this.load();
          this.dialogFormVisible = false;
        });
      }
    },
    del(id) {
      API.delete(url + "/" + id).then((res) => {
        this.$message({
          type: "success",
          message: "操作成功",
        });
        this.load();
      });
    },
  },
};
</script>

<style scoped>
.el-form-item__label {
        text-align: justify;
    }
</style>
